import { getObserver } from "./utils";

export function Reactive(name?: string) {
  return function (prototype: any, memberName: string) {
    const publicName = name || memberName;

    Object.defineProperty(prototype, memberName, {
      get: function () {
        return getObserver(this, publicName).current;
      },
      set: function (value: any) {
        getObserver(this, publicName, value).current = value;
      },
    });
    if (publicName !== memberName) {
      Object.defineProperty(prototype, publicName, {
        get: function () {
          return getObserver(this, publicName).current;
        },
      });
    }
  };
}

